import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Facebook,
  Instagram,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Adam Levy"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Adam Levy"
        videoUrl="https://vimeo.com/493924808/d9a25ae1be"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"nicholas"}
                fluid={data.adamLevyImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box width={["100%", null, 8 / 12, null]}>
          <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Adam Levy
            </Text>
            <Text level="1" mb="1.2rem">
              Guitarist and performing artist
            </Text>
            <Box>
              <Link to="https://www.instagram.com/stringjuggler/" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link
                to="https://www.facebook.com/Adam-Levy-108082175886114/"
                as="a"
              >
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              An imaginative guitarist, songwriter, and composer, Adam Levy has
              worked alongside a range of esteemed artists — from Norah Jones to
              Vulfpeck; from Allen Toussaint to Lisa Loeb. He has released more
              than a dozen albums as a collaborator and as a leader, including
              his recent Accidental Courage (featuring Darryl Jones, Charley
              Drayton, and Madison Cunningham).
            </Text>
            <Text level="2">
              Mentoring has long been a vital component of Levy’s musical life
              as well. He has taught courses and private lesson at USC, NYU, and
              Los Angeles College of Music, and is the host of the popular
              YouTube series Guitar Tips as well as Guitar Tips Pro on Patreon.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Adam Levy" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    adamLevyImage: file(relativePath: { eq: "adamLevy-L.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
